import React from "react"
import BlogArticle from "./blogArticle"
import { graphql } from "gatsby"

export const query = graphql`
  query($slug: String, $locale: String) {
    contentfulBlogs(slug: { eq: $slug }, node_locale: { eq: $locale }) {
      slug
      blogPublishDate(formatString: "DD MMM YYYY")
      blogTitle
      seoTitle
      seoDescription
      extraBlogImages {
        fluid {
          ...GatsbyContentfulFluid
        }
      }
      blogMainImage {
        fluid {
          ...GatsbyContentfulFluid
        }
      }
      blogSubTitle {
        childMarkdownRemark {
          html
        }
      }
      blogDescription {
        childMarkdownRemark {
          html
        }
      }
      eachExtraBlogDescription {
        descriptions
      }
    }

    allContentfulBlogs(
      filter: { node_locale: { eq: $locale }, slug: { ne: $slug } }
    ) {
      edges {
        node {          
          slug
          blogPublishDate
          blogTitle
          seoTitle
          seoDescription
          extraBlogImages {
            fluid {
              ...GatsbyContentfulFluid
            }
          }
          blogMainImage {
            fluid {
              ...GatsbyContentfulFluid
            }
          }
          blogSubTitle {
            childMarkdownRemark {
              html
            }
          }
          blogDescription {
            childMarkdownRemark {
              html
            }
          }
          eachExtraBlogDescription {
            descriptions
          }
        }
      }
    }
    file(name: { eq: "FundoGiris" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

const BlogArticleTemplate = ({
  data: { contentfulBlogs, allContentfulBlogs, file },
  pageContext,
  location,
}) => {
  const {
    slug,
    blogPublishDate,
    blogTitle,
    seoTitle,
    seoDescription,
    extraBlogImages,
    blogMainImage,
    blogSubTitle,
    blogDescription,
    eachExtraBlogDescription,
  } = contentfulBlogs
  return (
    <BlogArticle
      similarBlogArticles={allContentfulBlogs.edges}
      slug={slug}
      blogPublishDate={blogPublishDate}
      blogTitle={blogTitle}
      seoTitle={seoTitle}
      seoDescription={seoDescription}
      extraBlogImages={extraBlogImages}
      blogMainImage={blogMainImage}
      blogSubTitle={blogSubTitle}
      blogDescription={blogDescription}
      eachExtraBlogDescription={eachExtraBlogDescription}
      pageContext={pageContext}
      location={location}
      contactImage={file}
    />
  )
}

export default BlogArticleTemplate
