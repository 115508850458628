import React from "react"
import Img from "gatsby-image"
import {
  Typography,
  Container,
  Grid,
  Divider,
  Box,
  SvgIcon,
  IconButton,
} from "@material-ui/core"
import {
  makeStyles,
  createMuiTheme,
  ThemeProvider,
} from "@material-ui/core/styles"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faFacebook,
  faTwitter,
  faWeixin,
  faWeibo,
} from "@fortawesome/free-brands-svg-icons"
import Layout from "../../components/Layout"
import BlogTitle from "../../components/BlogTitle"
import Footer from "../../components/Footer"
import Textfield from "../../components/TextField"
import zhutravelTheme from "../../components/Layout/zhutravelTheme"
import { SEO } from "../../components/SEO"

const blogTheme = createMuiTheme(zhutravelTheme, {
  overrides: {
    MuiTypography: {
      body1: {
        fontSize: "1.25rem",
        lineHeight: "26px",
      },
    },
  },
})

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(6),
    [theme.breakpoints.up("md")]: {
      marginTop: theme.spacing(10),
    },
  },
  blogImage: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  divider: {
    marginTop: theme.spacing(8),
    [theme.breakpoints.up("md")]: {
      marginTop: theme.spacing(13),
    },
  },
  shareBox: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(10),
    [theme.breakpoints.up("md")]: {
      marginBottom: theme.spacing(13),
    },
  },
  shareText: {
    fontFamily: "MabryProRegular",
    fontSize: "1rem",
    lineHeight: "20px",
  },
}))

const socialIcons = [faFacebook, faTwitter, faWeixin, faWeibo]

const BlogArticle = ({
  blogTitle,
  blogPublishDate,
  blogSubTitle,
  blogMainImage,
  blogDescription,
  eachExtraBlogDescription,
  extraBlogImages,
  similarBlogArticles,
  contactImage,
  pageContext,
  location,
  seoTitle,
  seoDescription,
}) => {
  const classes = useStyles()

  return (
    <Layout location={location} pageContext={pageContext}>
      <SEO
        seoData={{
          title: seoTitle,
          description: seoDescription,
        }}
      />
      <ThemeProvider theme={blogTheme}>
        <Container className={classes.root}>
          <Grid container spacing={3} justify="center">
            <Grid item md={6}>
              <Typography variant="overline">{blogPublishDate}</Typography>
              <Typography variant="h2" component="h1">
                {blogTitle}
              </Typography>
              <Typography
                dangerouslySetInnerHTML={{
                  __html: blogSubTitle.childMarkdownRemark.html,
                }}
              />
            </Grid>
            <Grid item xs={12} className={classes.blogImage}>
              <Img fluid={blogMainImage.fluid} style={{ height: "400px" }} />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography
                dangerouslySetInnerHTML={{
                  __html: blogDescription?.childMarkdownRemark.html,
                }}
              />
            </Grid>
            {extraBlogImages && (
              <Grid item xs={12} md={8} className={classes.blogImage}>
                <Img
                  fluid={extraBlogImages[0]?.fluid}
                  style={{ height: "480px" }}
                />
              </Grid>
            )}
            <Grid item xs={12} container justify="center">
              <Grid item xs={12} md={6}>
                {eachExtraBlogDescription && (
                  <Typography>
                    {eachExtraBlogDescription.descriptions[0]}
                  </Typography>
                )}
                <Divider className={classes.divider} />
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  className={classes.shareBox}
                >
                  <Typography className={classes.shareText}>
                    Share on
                  </Typography>
                  <Box display="flex">
                    {socialIcons.map((icon, i) => (
                      <IconButton
                        key={i}
                        edge={
                          i === 0
                            ? "start"
                            : i === socialIcons.length - 1
                            ? "end"
                            : "false"
                        }
                      >
                        <SvgIcon>
                          <FontAwesomeIcon icon={icon} />
                        </SvgIcon>
                      </IconButton>
                    ))}
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </ThemeProvider>
      <Divider />
      <BlogTitle
        title="Similar articles"
        description=""
        blogs={similarBlogArticles}
        viewAllString="View all"
      />
      <Divider />
      <Textfield
        backgroundColor={"black"}
        customImage={contactImage.childImageSharp}
        locale={pageContext.locale}
      />
      <Divider />
      <Footer locale={pageContext.locale} />
    </Layout>
  )
}

export default BlogArticle
